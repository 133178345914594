table {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    color: black;

    tr {
        &:nth-child(even) {
            background: rgba(0,0,0,0.1);
        }

        th {
            background: rgba($color-primary, 1);
            color: text-color($color-primary);

            &:first-of-type {
                border-top-left-radius: 5px;
            }

            &:last-of-type {
                border-top-right-radius: 5px;
            }
        }

        th,
        td {
            padding: 0.5rem;
            border-left: 1px solid rgba(0,0,0,0.05);

            &:first-of-type {
                border-left: none;
            }
        }
    }

    tfoot {
        tr:last-child {
            td {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                border-bottom: 1px solid rgba(0,0,0,0.3);
            }
        }
    }
}
