form {
    margin: 10px 0;

    &.form-inline {
        display: inline-block;

        select, label, input, button {
            display: inline-block;
        }

        margin-bottom: 20px;
    }

    label, input, button, span, select {
        display: block;

        + label, + button {
            margin-top: 10px;
        }
    }

    input[disabled=disabled] {
        background-color: rgb(200,200,200);
    }

    label {
        &.is-invalid {
            color: $color-danger;
            input {
                border-color: $color-danger;
            }
        }
    }

    [type='checkbox'], [type='radio'] {
        &:focus {
            box-shadow: rgb(255, 255, 255) 0 0 0 2px, $color-primary 0 0 0 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
        }

        &:checked {
            background-color: $color-primary;

            &:hover, &:focus {
                background-color: $color-primary;
            }
        }
    }
}
