header {
    background-color: $color-primary;
    color: text-color($color-primary);

    .header {
        display: flex;
        justify-content: space-between;
        min-height: 50px;
        align-items: baseline;

        .navigation {
            display: flex;
            justify-content: center;

            .nav-link {
                display: block;
                padding: 1rem;
                border-bottom: 3px solid transparent;

                &.active, &:hover {
                    border-bottom-color: $color-secondary;
                }
            }
        }

        .user-actions {
            position: relative;
            cursor: default;

            &:hover {
                .dropdown {
                    display: block;
                }
            }

            .dropdown {
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                background-color: $color-primary;
                box-shadow: 0 6px 5px 5px rgba(0,0,0,0.1);

                .dropdown-menu {
                    list-style: none;

                    .dropdown-menu-item {
                        .dropdown-menu-item-link {
                            display: block;
                            padding: 1rem;
                            border-bottom: 3px solid rgba(0,0,0,0.3);
                            white-space: nowrap;
                            width: 100%;

                            &:hover {
                                border-bottom-color: $color-secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}
