/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #efefef;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
body {
  font-family: Roboto, sans-serif;
}

.header {
  display: flex;
  padding: 0 1rem;
  background: #FF6600;
  align-items: center;
  gap: 1rem;
}
.header .nav {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
}
.header .nav .nav-item {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.header .nav .nav-item:last-of-type {
  border-right: none;
}
.header .nav .nav-item .nav-link {
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: black;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 200ms;
}
.header .nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

table {
  border-collapse: collapse;
}
table thead th {
  background-color: #FF6600;
  padding: 0 1rem;
}
table thead th:first-of-type {
  border-top-left-radius: 5px;
}
table thead th:last-of-type {
  border-top-right-radius: 5px;
}
table tbody tr td {
  background-color: white;
  padding: 0.5rem 1rem;
  cursor: default;
}
table tbody tr td.no-results {
  text-align: center;
}
table tbody tr td.actions {
  display: flex;
  gap: 10px;
}
table tbody tr:nth-child(odd) td {
  background-color: #e0e0e0;
}
table tbody tr:hover {
  color: #FF6600;
}

.manual-input {
  margin-top: 1rem;
}

.title {
  text-align: center;
}

.calculator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
}

.price {
  font-size: 2rem;
}

form label, form button, form input, form select, form textarea {
  display: block;
}
form label + label, form label + button, form button + label, form button + button, form input + label, form input + button, form select + label, form select + button, form textarea + label, form textarea + button {
  margin-top: 10px;
}
form + table {
  margin-top: 10px;
}
form .buttons-horizontal {
  display: flex;
  gap: 10px;
}
form label.is-invalid {
  color: #AA1100;
}
form label.is-invalid input, form label.is-invalid select {
  border-color: #AA1100;
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.messages .message {
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid;
}
.messages .message-success {
  border-color: #c3e6cb;
  background: #d4edda;
  color: #155724;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  gap: 0.5rem;
}
.pagination .page-item {
  display: block;
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 200ms;
}
.pagination .page-item .page-link {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 0.5rem;
  border: 1px solid #000;
}
.pagination .page-item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.pagination .page-item.active:hover, .pagination .page-item.disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}
.pagination .page-item.active {
  font-weight: bold;
}
.pagination .page-item.active .page-link {
  text-decoration: underline;
}
.pagination .page-item.disabled .page-link {
  color: rgba(0, 0, 0, 0.3);
  border-color: rgba(0, 0, 0, 0.3);
}

.button {
  display: block;
  text-decoration: none;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 200ms;
  border-radius: 3px;
}
.button-primary {
  background-color: #FF6600;
  color: black;
}
.button-primary:hover {
  background-color: rgba(255, 102, 0, 0);
  color: #FF6600;
  border-color: #FF6600;
}
.button-secondary {
  background-color: rgba(255, 102, 0, 0);
  color: #FF6600;
  border-color: #FF6600;
}
.button-secondary:hover {
  background-color: #FF6600;
  color: black;
  border-color: transparent;
}
.button-reset {
  background-color: #999999;
  color: black;
}
.button-reset:hover {
  background-color: rgba(255, 102, 0, 0.3);
  color: black;
  border-color: rgba(255, 102, 0, 0.3);
}
.button-danger {
  background-color: #AA1100;
  color: rgba(255, 255, 255, 0.9);
}
.button-danger:hover {
  background-color: rgba(170, 17, 0, 0);
  color: #AA1100;
  border-color: #AA1100;
}

h2 {
  font-size: 1.5rem;
  color: #FF6600;
}

.container {
  display: block;
  max-width: 1320px;
  margin: auto;
}

header {
  background-color: #FF6600;
  color: #FFF;
}
header .header {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: baseline;
}
header .header .navigation {
  display: flex;
  justify-content: center;
}
header .header .navigation .nav-link {
  display: block;
  padding: 1rem;
  border-bottom: 3px solid transparent;
}
header .header .navigation .nav-link.active, header .header .navigation .nav-link:hover {
  border-bottom-color: #ff944f;
}
header .header .user-actions {
  position: relative;
  cursor: default;
}
header .header .user-actions:hover .dropdown {
  display: block;
}
header .header .user-actions .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #FF6600;
  box-shadow: 0 6px 5px 5px rgba(0, 0, 0, 0.1);
}
header .header .user-actions .dropdown .dropdown-menu {
  list-style: none;
}
header .header .user-actions .dropdown .dropdown-menu .dropdown-menu-item .dropdown-menu-item-link {
  display: block;
  padding: 1rem;
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  width: 100%;
}
header .header .user-actions .dropdown .dropdown-menu .dropdown-menu-item .dropdown-menu-item-link:hover {
  border-bottom-color: #ff944f;
}

table {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  color: black;
}
table tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.1);
}
table tr th {
  background: #ff6600;
  color: #FFF;
}
table tr th:first-of-type {
  border-top-left-radius: 5px;
}
table tr th:last-of-type {
  border-top-right-radius: 5px;
}
table tr th,
table tr td {
  padding: 0.5rem;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}
table tr th:first-of-type,
table tr td:first-of-type {
  border-left: none;
}
table tfoot tr:last-child td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  transition: 200ms all;
  font-size: 1rem;
}
.btn.btn-primary {
  background-color: #FF6600;
  color: #FFF;
}
.btn.btn-primary:hover {
  border-color: #FF6600;
  background-color: transparent;
  color: #FF6600;
}
.btn.btn-secondary {
  background-color: #ff944f;
  color: #000;
}
.btn.btn-secondary:hover {
  border-color: #ff944f;
  background-color: transparent;
  color: #ff944f;
}
.btn.btn-outline-primary {
  background-color: transparent;
  color: #FF6600;
  border-color: #FF6600;
}
.btn.btn-outline-primary:hover {
  border-color: #FF6600;
  background-color: #FF6600;
  color: #FFF;
}
.btn.btn-outline-secondary {
  background-color: transparent;
  color: #ff944f;
  border-color: #ff944f;
}
.btn.btn-outline-secondary:hover {
  border-color: #ff944f;
  background-color: #ff944f;
  color: #000;
}
.btn.btn-danger {
  background-color: #AA1100;
  color: #FFF;
}
.btn.btn-danger:hover {
  background-color: rgba(170, 17, 0, 0.25);
  color: #AA1100;
  border-color: #AA1100;
  transform: scale(1.02);
}
.btn.btn-reset {
  background-color: #aaa;
  color: black;
}
.btn.btn-reset:hover {
  background-color: black;
  color: white;
}

form {
  margin: 10px 0;
}
form.form-inline {
  display: inline-block;
  margin-bottom: 20px;
}
form.form-inline select, form.form-inline label, form.form-inline input, form.form-inline button {
  display: inline-block;
}
form label, form input, form button, form span, form select {
  display: block;
}
form label + label, form label + button, form input + label, form input + button, form button + label, form button + button, form span + label, form span + button, form select + label, form select + button {
  margin-top: 10px;
}
form input[disabled=disabled] {
  background-color: #c8c8c8;
}
form label.is-invalid {
  color: #AA1100;
}
form label.is-invalid input {
  border-color: #AA1100;
}
form [type=checkbox]:focus, form [type=radio]:focus {
  box-shadow: white 0 0 0 2px, #FF6600 0 0 0 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}
form [type=checkbox]:checked, form [type=radio]:checked {
  background-color: #FF6600;
}
form [type=checkbox]:checked:hover, form [type=checkbox]:checked:focus, form [type=radio]:checked:hover, form [type=radio]:checked:focus {
  background-color: #FF6600;
}

.multi-select {
  display: block;
  margin: 13px 0;
  padding: 8px;
  position: relative;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.multi-select.is-invalid {
  border-color: #AA1100;
}
.multi-select.is-invalid .multi-select-title {
  color: #AA1100;
}
.multi-select .multi-select-title {
  position: absolute;
  top: -13px;
  left: 16px;
  background-color: rgb(243 244 246/var(--tw-bg-opacity));
  padding: 0 5px;
}
.multi-select label:nth-child(2) {
  margin-top: 5px;
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.messages .message {
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid;
}
.messages .message-success {
  border-color: #c3e6cb;
  background: #d4edda;
  color: #155724;
}

main {
  padding-bottom: 1rem;
}
main .page-header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
main .page-header .page-title {
  font-size: 1.7rem;
  color: #FF6600;
}

@tailwind base;
@tailwind utilities;