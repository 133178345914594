.btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    transition: 200ms all;
    font-size: 1rem;

    &.btn-primary {
        background-color: $color-primary;
        color: text-color($color-primary);

        &:hover {
            border-color: $color-primary;
            background-color: transparent;
            color: $color-primary;
        }
    }

    &.btn-secondary {
        background-color: $color-secondary;
        color: text-color($color-secondary);

        &:hover {
            border-color: $color-secondary;
            background-color: transparent;
            color: $color-secondary;
        }
    }


    &.btn-outline-primary {
        background-color: transparent;
        color: $color-primary;
        border-color: $color-primary;

        &:hover {
            border-color: $color-primary;
            background-color: $color-primary;
            color: text-color($color-primary);
        }
    }

    &.btn-outline-secondary {
        background-color: transparent;
        color: $color-secondary;
        border-color: $color-secondary;

        &:hover {
            border-color: $color-secondary;
            background-color: $color-secondary;
            color: text-color($color-secondary);
        }
    }

    &.btn-danger {
        background-color: $color-danger;
        color: text-color($color-danger);

        &:hover {
            background-color: rgba($color-danger, 0.25);
            color: $color-danger;
            border-color: $color-danger;
            transform: scale(1.02);
        }
    }

    &.btn-reset {
        background-color: $color-reset;
        color: black;

        &:hover {
            background-color: black;
            color: white;
        }
    }
}
