main {
    padding-bottom: 1rem;

    .page-header {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .page-title {
            font-size: 1.7rem;
            color: $color-primary;
        }
    }
}
