table {
    border-collapse: collapse;
    thead {
        th {
            background-color: $color-primary;
            padding: 0 1rem;

            &:first-of-type {
                border-top-left-radius: 5px;
            }

            &:last-of-type {
                border-top-right-radius: 5px;
            }
        }
    }

    tbody {
        tr {
            td {
                background-color: white;
                padding: 0.5rem 1rem;
                cursor: default;

                &.no-results {
                    text-align: center;
                }

                &.actions {
                    display: flex;
                    gap: 10px;
                }
            }

            &:nth-child(odd) {
                td {
                    background-color: #e0e0e0;
                }
            }

            &:hover {
                color: $color-primary;
            }
        }
    }
}
