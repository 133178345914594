form {
    label, button, input, select, textarea {
        display: block;

        + label, + button {
            margin-top: 10px;
        }
    }

    + table {
        margin-top: 10px;
    }

    .buttons-horizontal {
        display: flex;
        gap: 10px;
    }

    label.is-invalid {
        color: $color-danger;

        input, select {
            border-color: $color-danger;
        }
    }
}
