.header {
    display: flex;
    padding: 0 1rem;
    background: $color-primary;
    align-items: center;
    gap: 1rem;

    .nav {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;

        .nav-item {
            border-right: 1px solid rgba(255, 255, 255, 0.4);

            &:last-of-type {
                border-right: none;
            }

            .nav-link {
                display: block;
                padding: 1rem;
                text-decoration: none;
                color: black;
                background-color: rgba(255, 255, 255, 0);
                transition: background-color 200ms;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.3);
                }
            }
        }
    }
}
