// Variables. Override these in you own variable file if needed
$color-primary: #FF6600 !default;
$color-secondary: #ff944f !default;
$color-danger: #fc3838 !default;
$color-reset: #aaa !default;

$color-text-header: white !default;

$color-border-success: #c3e6cb !default;
$color-background-success: #d4edda !default;
$color-text-success: #155724 !default;

$site-width: 1320px !default;

// Import the theme
@import "vendor/diesite-admin-theme/admin-theme";
