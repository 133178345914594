$default-color: #000;
$disabled-color: rgba($default-color, 0.3);
$default-background-color: rgba($default-color, 0);

.pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    gap: 0.5rem;

    .page-item {
        display: block;
        cursor: default;
        background-color: $default-background-color;
        transition: background-color 200ms;

        .page-link {
            display: block;
            text-decoration: none;
            color: $default-color;
            padding: 0.5rem;
            border: 1px solid $default-color;
        }

        &:hover {
            cursor: pointer;
            background-color: rgba($default-background-color, 0.1);
        }

        &.active, &.disabled {
            &:hover {
                cursor: default;
                background-color: $default-background-color;
            }
        }

        &.active {
            font-weight: bold;

            .page-link {
                text-decoration: underline;
            }
        }

        &.disabled {

            .page-link {
                color: $disabled-color;
                border-color: $disabled-color;
            }
        }
    }
}
