.title {
    text-align: center;
}

.calculator {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 20px;
}

.price {
    font-size: 2rem;
}
