.multi-select {
    display: block;
    margin: 13px 0;
    padding: 8px;
    position: relative;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.2);

    &.is-invalid {
        border-color: $color-danger;

        .multi-select-title {
            color: $color-danger;
        }
    }

    .multi-select-title {
        position: absolute;
        top: -13px;
        left: 16px;
        background-color: rgb(243 244 246 / var(--tw-bg-opacity));
        padding: 0 5px;
    }

    label:nth-child(2) {
        margin-top: 5px;
    }
}
