.messages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message {
        padding: 0.5rem;
        margin: 0.5rem;
        border: 1px solid;

        &-success {
            border-color: $color-border-success;
            background: $color-background-success;
            color: $color-text-success;
        }
    }
}
