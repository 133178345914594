.button {
    display: block;
    text-decoration: none;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: 1px solid rgba(0,0,0,0);
    cursor: pointer;
    transition: all 200ms;
    border-radius: 3px;

    &-primary {
        background-color: $color-primary;
        color: black;

        &:hover {
            background-color: rgba($color-primary, 0);
            color: $color-primary;
            border-color: $color-primary;
        }
    }

    &-secondary {
        background-color: rgba($color-primary, 0);
        color: $color-primary;
        border-color: $color-primary;;

        &:hover {
            background-color: $color-primary;
            color: black;
            border-color: transparent;
        }
    }

    &-reset {
        background-color: #999999;
        color: black;

        &:hover {
            background-color: rgba($color-primary, 0.3);
            color: black;
            border-color: rgba($color-primary, 0.3);
        }
    }

    &-danger {
        background-color: $color-danger;
        color: rgba(255, 255, 255, 0.9);

        &:hover {
            background-color: rgba($color-danger, 0);
            color: $color-danger;
            border-color: $color-danger;
        }
    }
}
